import React, { useEffect, useRef } from 'react';

type HiddenFormProps = {
  method: 'get' | 'post';
  action: string;
  fields: Record<string, string | number>;
  onComplete?(): void;
};

const HiddenForm: React.FC<HiddenFormProps> = ({ method, action, fields, onComplete }) => {
  const inputSubmit = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputSubmit.current?.click();
  }, [action]);

  const handleSubmit = () => {
    onComplete?.();
  }

  return (
    <form method={method} action={action} style={{ display: 'none' }} onSubmit={handleSubmit}>
      {Object.entries(fields).map(([key, value]) => (
        <input key={key} type="hidden" name={key} value={value} />
      ))}
      <input type="submit" ref={inputSubmit} />
    </form>
  );
};

export default HiddenForm;
